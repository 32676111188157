import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import {Helmet} from "react-helmet";
// components
import HeaderQuery from "../components/header/header"
import BasicBlock from "../components/basicBlock/basicBlock";
import CardContainer from "../components/cardContainer/cardContainer";
import CardOneService from "../components/card/cardOneService";
import BasicTitle from "../components/basicTitle/basicTitle";
import Footer from "../components/footer/footer";
// style
import '../styles/services.css'

const Services = ({data, path}) => {
  const {headerPicture, firstBlock} = data;
  const {
    applicationMobileCard,
    blogCard,
    codingCard,
    refonteCard,
    shoppingCard,
    siteVitrineCard
  } = data;

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="fr" />
        <title>Agence</title>
        <link rel="canonical" href="https://webdevvision.fr/services/" />
        <meta
          name="description"
          content="Nous sommes une agence de développement Web et Mobile sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’applications web et mobile."
        />
      </Helmet>
      <HeaderQuery />
      <BasicBlock
        containerLeft={() => (
          <>
            <h1 className="primary services-header">
              Nos Services
            </h1>
            <p className="secondary services-subTitle">
              Vous souhaitez développer un dispositif digital ?<br/>
              Site vitrine, e-commerce ou application web.<br/>
              Chez WebDevVision, notre savoir-faire permet de concevoir des produits répondant pleinement à vos besoins.
            </p>
          </>
        )}
        containerRight={() => (
          <>
            <Img imgStyle={{ objectFit: 'contain' }} fluid={headerPicture.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile
        containerRightContainPicture
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <CardContainer isFullResponsive={false}>
        <CardOneService
          borderColor="primary"
          title={
            <h3 className="services-card-title secondary">
              Site vitrine
            </h3>
          }
          text={
            <p className="services-card-text secondary">
              Présente l'activité de votre entreprise en quelques
              pages <span className="primary bold">optimisées</span> et met en avant vos activités,
              produits, services, actualités, etc. Il est la pierre angulaire de votre stratégie web.
            </p>
          }
          picture={
            <Img fluid={siteVitrineCard.childImageSharp.fluid}  />
          }
        />
        <CardOneService
          borderColor="primary"
          title={
            <h3 className="services-card-title secondary">
              Site E-commerce
            </h3>
          }
          text={
            <p className="services-card-text secondary">
              Création et développement de votre site e-commerce. Avec des sites édités sur les
              CMS <span className="primary bold">Prestashop</span> ou <span className="primary bold">Magento</span>,
              vous aurez la possibilité d’administrer votre boutique en ligne.
            </p>
          }
          picture={
            <Img fluid={shoppingCard.childImageSharp.fluid}  />
          }
        />
        <CardOneService
          borderColor="primary"
          title={
            <h3 className="services-card-title secondary">
              Application Mobile
            </h3>
          }
          text={
            <p className="services-card-text secondary">
              Nous vous <span className="primary bold">accompagnons</span> dans les différentes étapes de votre projet qui vont de la réflexion initiale
              à sa conception finale et jusqu’à sa publication sur l’App Store d’Apple et le Play Store d’Android.
            </p>
          }
          picture={
            <Img imgStyle={{ objectFit: 'contain' }} fluid={applicationMobileCard.childImageSharp.fluid}  />
          }
        />
        <CardOneService
          borderColor="primary"
          title={
            <h3 className="services-card-title secondary">
              Refonte d'un site existant
            </h3>
          }
          text={
            <p className="services-card-text secondary">
              L’expérience utilisateur <span className="primary bold">évolue</span> sans cesse,
              et les objectifs d’un site aussi. Refondre son site permet de proposer un nouveau visuel
              plus <span className="primary bold">ergonomique</span> correspondant aux usages actuels,
              mais aussi d'ajouter de nouvelles fonctionnalités.
            </p>
          }
          picture={
            <Img imgStyle={{ objectFit: 'contain' }} fluid={refonteCard.childImageSharp.fluid}  />
          }
        />
        <CardOneService
          borderColor="primary"
          title={
            <h3 className="services-card-title secondary">
              Application Web sur mesure
            </h3>
          }
          text={
            <p className="services-card-text secondary">
              Nous vous accompagnons dans la conception et le développement de
              votre application web <span className="primary bold">sur mesure</span> en
              alliant créativité, simplicité et efficacité.
              Les sites que nous créons sont <span className="primary bold">entièrement administrables</span> et
              livrées clés en main (formation comprise).
            </p>
          }
          picture={
            <Img fluid={codingCard.childImageSharp.fluid}  />
          }
        />
        <CardOneService
          borderColor="primary"
          title={
            <h3 className="services-card-title secondary">
              Blog optimisé SEO
            </h3>
          }
          text={
            <p className="services-card-text secondary">
              Le blog de votre entreprise complète et <span className="primary bold">renforce</span> votre
              site web avec un contenu régulièrement mis à jour.
              Nous vous proposons la création d'un blog fait sur mesure et
              optimisé pour le <span className="primary bold">SEO</span>.
              Vous pourrez changer à votre guise le contenu de celui-ci.
            </p>
          }
          picture={
            <Img fluid={blogCard.childImageSharp.fluid}  />
          }
        />
      </CardContainer>
      <BasicTitle title="Quelques précision" colorText="other" colorSpan="other" />
      <BasicBlock
        containerLeft={() => (
          <p className="secondary">
            Pour chaque projet nous prenons le temps de bien définir le besoin client.
            Cela peut prendre plusieurs réunions mais nous pensons que c'est primordial pour le bon déroulement du projet.
            Une fois votre besoin écrit sur papier nous élaborons un cahier des charges et un devis.
            <br/><br/>
            Une fois le devis signé on developpe de façon "AGILE" par sprint de 1 à 2 semaines.
            Kézako ? Pour faire simple un sprint a pour but de réaliser "quelque chose" de présentable à un client.
            À la fin de celui-ci un déploiement de la solution est fait sur un serveur de test.
            <br/><br/>
            Vous serez le seul à y avoir accès et ça vous permettra de tester votre solution.
            De semaine en semaine vous verrez l'évolution de votre projet jusqu'à son rendu final.
            Cette méthode permet d'allier rapidité, efficacité et visibilité.
          </p>
        )}
        containerRight={() => (
          <>
            <Img fluid={firstBlock.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile
        containerRightContainPicture
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <Footer path={path} />
    </main>
  )
}

export const query = graphql`
  query {
    headerPicture: file(relativePath: { eq: "services-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    firstBlock: file(relativePath: { eq: "services-blockOne.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    applicationMobileCard: file(relativePath: { eq: "application-mobile-card.png" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogCard: file(relativePath: { eq: "blog-card.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    codingCard: file(relativePath: { eq: "coding-card.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    refonteCard: file(relativePath: { eq: "refonte-card.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shoppingCard: file(relativePath: { eq: "shopping-card.png" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    siteVitrineCard: file(relativePath: { eq: "site-vitrine-card.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Services
