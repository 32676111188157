import React from 'react'
import PropTypes from "prop-types";
import {AnchorLink} from "gatsby-plugin-anchor-links";
// style
import './cardOneService.css'

const CardOneService = ({title, text, picture}) => (
  <AnchorLink
    to="/contact#form"
    className="cos-container"
  >
    <div className="cos-top-picture">
      {picture}
    </div>
    {title}
    {text}
  </AnchorLink>
)

CardOneService.propTypes = {
  title: PropTypes.element.isRequired,
  text: PropTypes.element.isRequired,
  picture: PropTypes.element.isRequired,
}

export default CardOneService
